.dashboard-container {
    padding: 20px;
    background-color: #cde1d6;
    min-height: 100vh;
  }
  
  .glass-container {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .flex-row-container-header2{
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    margin-bottom: 20px;
    .post{
        margin-left: auto;
    } 
    h1{
        margin-bottom: 0;
        text-align: left;
        line-height: 1.5;
        font-weight: 700;
    }   
   }

   .button-container {
      display: flex;
      gap: 10px;
   }

   .dashboard-internship-title-container2 {
    height: 20px;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1fr 1fr; /* Adjusted column sizes */
    gap: 30px;
    grid-template-rows: 1fr;
}

.dashboard-internship-info-title:last-child {
    margin-left: 30%; /* Add margin to move "Interview Results" to the right */
    text-align: left; /* Optional to align the text within the column */
}
  
  .back-button-applicantList {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .sub-title {
    font-size: 14px;
    color: #1DAF5C;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .dashboard-internship-title-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
    text-align: left;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .dashboard-divider-line {
    margin: 10px 0;
  }
  
  .dashboard-internships-info-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .dashboard-internship-row {
    display: grid;
    grid-template-columns: 0.8fr 1.0fr 1.8fr 1.5fr 1.2fr;
    align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-internship-info {
    text-align: left;
    font-size: 14px;
  }
  
  .status-applied {
    color: #555;
    font-weight: bold;
  }
  
  .view-results-button {
    background-color: #233242;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .inline-modal {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    width: 100%; /* Adjust width as needed */
    z-index: 1; /* Ensure it appears on top */
  }

  .modal-grid {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Left column 1fr, Right column 2fr */
    gap: 20px; /* Space between columns */
  }

  .left-column {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between items */
  }

  .right-column {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Spacing between items */
    overflow-y: auto; /* Scroll if content overflows */
    max-height: 80vh; /* Prevent overflowing modal */
  }
  
  .modal-content2 {
    background-color: #fefefe;
    flex-direction: column;
    padding: 10px;
    /* width: 30%; */
    z-index: 20;
    align-items: center;
  }

  .date-text {
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .location-status {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  
  .location-icon {
    width: 16px; /* Adjust the width as needed */
    height: 16px; /* Adjust the height as needed */
    margin-right: 8px; /* Spacing between the icon and the status text */
  }

  .hire-reject-buttons {
    display: flex;
    gap: 10px; /* Adjust spacing between buttons */
    margin-top: 10px; /* Add some spacing from the element above */
  }
  
  .hire-button {
    background-color: #4caf50; /* Green background */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hire-button:hover {
    background-color: #45a049; /* Slightly darker green on hover */
  }
  
  .reject-button {
    background-color: #f44336; /* Red background */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
  }
  
  .reject-button:hover {
    background-color: #e53935; /* Slightly darker red on hover */
  }

  .loaderForInterviewSent {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .custom-email-sent {
    display: flex !important;
    align-items: flex-start !important;
  }
  
  input[type="checkbox"] {
    cursor: pointer;
  }

  .qa-question-text-qbqAnalysis {
    font-size: 14px; /* Same as accordion-body text */
    margin: 0; /* Remove any default margin */
    font-weight: 400; /* Bold for emphasis */
  }

  .accordion-button-qbqAnalysis {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    cursor: pointer;
    background-color: transparent; /* Transparent background */
    border: none; /* Remove button border */
    padding: 10px 15px; /* Consistent padding */
    font-size: 16px; /* Match font size */
    transition: background-color 0.2s ease; /* Smooth hover */
  }

  .accordion-button-qbqAnalysis:hover {
    background-color: #f9f9f9; /* Light hover background */
  }

  .qa-toggle-icon-qbqAnalysis {
    margin-left: 10px; /* Space between question and icon */
    width: 16px; /* Icon size */
    height: 16px; /* Icon size */
  }

  .accordion-item-qbqAnalysis {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
  }

  .accordion-body-qbqAnalysis {
    font-size: 14px;
    line-height: 1.5;
  }

  .accordion-container-qbqAnalysis {
    margin: 5px 0;
    margin-left: -10px;
  }

  .accordion-header-qbqAnalysis {
    margin-bottom: 10px;
  }

  .accordion-collapse-qbqAnalysis {
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
  }

  .dashboard-add-job-button4 {
    background-color: #4caf50; /* Green background */
    margin-left: auto;
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }

  .dashboard-add-job-button4:hover {
    background-color: #3e8e41; 
  } 

  .download-candidates-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #e0e0e0; /* Light gray background */
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    color: #000; /* Black text */
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: all 0.3s ease; /* Smooth hover effect */
    margin-right: 10px;
  }
  
  .download-candidates-button:hover {
    background-color: #d6d6d6; /* Darker gray on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Slightly larger shadow */
  }
  
  .download-candidates-button:active {
    transform: translateY(2px); /* Button press effect */
  }
  
  .download-candidates-button img {
    width: 18px;
    height: 18px;
  }

  @media only screen and (max-width: 600px) {
    .dashboard-internship-title-container2 {
      height: 35px;
      grid-template-columns: 2.5fr 1fr 3.6fr; 
    }

    .dashboard-internship-row {
      grid-template-columns: 2.8fr 8.0fr 1.8fr;
    }

    .date-text {
      margin-bottom: 0px;
    }

    .modal-grid {
      grid-template-columns: 1fr; /* Stack the columns for mobile */
      gap: 10px; /* Adjust spacing for mobile */
    }
  
    .right-column {
      margin-top: 20px; /* Add space between left and right content */
    }
  }