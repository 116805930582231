.nav_container{
    background-color: #000000;
    padding: 1.0rem 4vw 2rem;
    width: 100vw;
    z-index: 2;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width:200px) {
    .hamburger_nav{
        display:none;
    }
    .nav_container .navbar_logo{
        min-width: 148px;
    }
    .nav_container .navbar_logo > img{
        width: 15vw;
        cursor: pointer;
    }
    .nav_left{
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .nav_right{
        display:flex;
        float:right;
        min-width:20vw;
        font-size:17px;
    }
    .nav_right > a{
        text-decoration: none;
        display:flex;
        align-items: center;
    }
    .nav_right > a> div {
        margin-left:0.5vw;
        margin-right: 0.5vw;

    }

    .nav_right > a > div.nav_login{
        background-color: white;
        color: black;
        font-weight: bold; /* Bold text */
        border: none; /* No border */
        font-family: 'Montserrat';
        text-decoration: none;
        font-size: 20px; /* Suitable text size */
        padding: 10px 20px; /* Padding around the text */
        border-radius: 5px;
    }
    .nav_right > a > div.nav_login > p{
        margin-top:0;
        margin-bottom:0;
    }
    .nav_right > a > div.nav_get_started{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1DAF5C;
        color: white;
        font-weight: bold; /* Bold text */
        border: none; /* No border */
        font-family: 'Montserrat';
        text-decoration: none;
        font-size: 20px; /* Suitable text size */
        padding: 10px 20px; /* Padding around the text */
        border-radius: 5px;
    }
    .nav_right > a > div.nav_get_started > p{
        margin-top:0;
        margin-bottom:0;
    }
}
.dropdown {
    background-color: #202020;
    width: 100vw;
    padding: 20px;
}
.dropdown a {
    color: white;
    font-weight: 500;
    text-decoration: none;
}
@media only screen and (max-width:600px) {
    .nav_left, .nav_right{
        display:none;
    }
    .hamburger_nav{
        display:flex;
        justify-content: space-between;
        width:100vw;
        z-index: 101;
    }
    .hamburger_nav > .hamburger_left >.navbar_logo{
        min-width: 148px;
    }
    .hamburger_nav > .hamburger_left > .navbar_logo > img{
        width: 15vw;
    }
    .hamburger_right{
        overflow-x: hidden;
    }
    .inactive{
        display:none;
    }
    .hamburger_logo > .nav_bar{
        height:2px;
        width:25px;
        background-color: white;
        margin-top:5px;
        border-radius: 1px;
    }
    .menu_container{
        position: absolute;
        /* transform-origin: 0% 0%; */
        background-color: white;
        transform: translate(50vw, 0);
        width:50vw;
        height:100vh;
        margin-top:-2.5rem;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .menu_container > ul{
        height:100%;
        width:100%;
        list-style-type: none;
        padding: 0;
    }
    .menu_container > ul> a{
        text-decoration: none;
        width:100%;
    }
    .menu_container > ul > a > li{
        width:100%;
        height:8%;
        background-color: transparent;
        font-size:1.5rem;
        color:black;
        font-weight:700;
        display:flex;
        padding-left:3vw;
        align-items: center;
        
    }
    .menu_container > ul > a > li:hover{
        background-color: rgb(201, 201, 201);
    }
    .active{
        transform: translate(-45vw, 0);
        position:fixed;
    }
    .menu_container > ul > li > .small_menu_top{
        display:flex;
        background-color: #203546;
        width:100%;
        height:7vh;
        
        justify-content: space-between;
    }
    .menu_container > ul > li > .small_menu_top > .small_menu_icon{
        display: flex;
        align-items: center;
    }
    .menu_container > ul > li > .small_menu_top > .small_menu_icon > img{
        /* border:solid 2px green; */
        width:15vw;
    }
    .close_icon{
        width: 25px;
        margin-top: 10px;
        position: relative;
        /* background-color: green; */
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .close_icon > span{
        top: 0;
        height:2px;
        width:20px;
        position: absolute;
        background-color: white;
    }
    .close_icon > span:nth-child(1){
        transform:rotate(45deg);
    }
    .close_icon > span:nth-child(2){
        transform:rotate(-45deg);
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }
    .hamburger_right .dropdown-nav{
        overflow: hidden;
        font-size:0;
        transition: font-size 10s linear;
    }
    .close-inactive{
        display:none;
        max-height:0;
    }
    .dropdown-nav.open-active{
        list-style: none;
        font-weight: 500;
        font-size: 1.25rem;
        padding-left:5vw;
    }
    .open-active  a{
        text-decoration: none;
        color:black;
    }
    .open-active  li{
        height: 5vh;
        width:100%;
        display:flex;
        align-items: center;
    }

    .hamburger_nav .hamburger_right li.li-dropdown-nav{
        display:flex;
        width: 100%;
        justify-content: space-between;
    }
    .hamburger_nav .hamburger_right li > img{
        width: calc(10px + 0.8vw);
        margin-right:2.5vw;
    }
    .hamburger_nav .hamburger_right li .nav-arrow{
        transition: transform 0.3s;
    }
    .hamburger_nav .hamburger_right li .nav-arrow.open{
        transform: rotate(180deg);
    }
    .hamburger_nav .nav-sign-up{
        border-radius: 5px;
        background-color:#213546;
        border:none;
        color:white;
        font-size:17px;
        padding:10px;
        width:100px;
        height:50px;
    }
    .hamburger_right .nav-login{
        border-radius: 5px;
        background-color:#1DAF5C;
        border:none;
        color:black;
        padding:10px;
        width:100px;
        height:50px;
        font-size:17px;
    }
    .hamburger_right .nav-button-container button{
        margin: 10px;
    }
    .hamburger_right .nav-button-container{
        display:flex;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin-top:10px;
    }
}
