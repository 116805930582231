.dashboard-container {
    grid-area: main;
    margin-left: 2vw;
    margin-right: 2vw;
    padding: 2rem;   
}
@media screen and (max-width: 1030px) {
    .dashboard-container{
        margin-left: 0;

    }
    .dashboard-internship-title-container-main{
        padding-left: 10px !important;
    grid-gap: 15px !important;
    gap: 15px !important;
    grid-gap: 10px;
    padding: 10px;
    margin-bottom: 10px;

    }
}
.flex-row-container-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 25px;
    .post{
        margin-left: auto;
    } 
    h1{
        margin-bottom: 0;
        text-align: left;
        line-height: 1.5;
        font-weight: 700;
    }   
}

.glass-container {
    background-color: rgba(255, 255, 255, 0.30); 
    backdrop-filter: blur(5px);
    padding: 40px;
    border-radius: 25px;
    border: none;
}

.dashboard-divider-line {
    border: none; /* Remove default border */
    border-top: 2px solid #1E293B; /* Color and thickness of the line */
    width: 100%; /* Full width of the container */
    margin: 0.5rem 0; /* Space above and below the line */
    opacity: 0.8; /* Slight transparency */
}

.dashboard-zero-internships {
    text-align: center; /* Center align the content */
    padding: 20px; /* Add some spacing */
}
  
.dashboard-zero-internships-text {
    font-size: 16px; /* Adjust text size */
    color: #000; /* Black text */
    line-height: 1.5; /* Spacing between lines */
    margin-bottom: 20px; /* Spacing between text and button */
}

.dashboard-add-job-button2 {
    background-color: #4caf50; /* Green background */
    margin-left: auto;
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
    margin-top: 3%;
    margin-bottom: 6%;
}

.dashboard-add-job-button3 {
    background-color: #4caf50; /* Green background */
    margin-left: auto;
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}
  
.dashboard-add-job-button2:hover {
    background-color: #3e8e41; /* Darker green on hover */
}

.dashboard-add-job-button3:hover {
    background-color: #3e8e41; /* Darker green on hover */
}
  
.dashboard-add-job-button {
    background-color: #4caf50; /* Green background */
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
    margin-top: 20px;
}
  
.dashboard-add-job-button:hover {
    background-color: #3e8e41; /* Darker green on hover */
}

.dashboard-internships-info-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: auto;
    margin-top: 20px;
}

.dashboard-internship-title-container-main {
    height: 30px;
    display: grid;
    grid-template-columns:  1.2fr 1fr 1.3fr 0.5fr 0.6fr;
    gap: 50px;
    grid-template-rows: 1fr;
    padding-left: 60px;    
}

@media screen and (max-width: 768px) {
    .glass-container{
        padding:20px;
    }
}
 
.dashboard-internship-info-title {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.dashboard-zero-internships-message {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .dashboard-internship-title-container-main {
        grid-template-columns:  1.2fr 1fr 1.3fr;
    }
}